<template>
  <div class="main_v8">
    <div class="plotarea">

      <div class="plotarea_header">
        <h3>Alarm Search</h3>
        
        <div class="dates">
          <div class="date">
            <span>Start</span> <input type="date" class="datepicker" onfocus="this.showPicker()" @change="handleSelectDateStart"/>
          </div>
          <div class="date">
            <span>End</span> <input type="date" class="datepicker" onfocus="this.showPicker()" @change="handleSelectDateEnd"/>
          </div>
        </div>

        <div class="actions">
          <div class="keyword_name">
            <span>Keywords</span> <input type="text" v-model="keywords" @keyup.enter="onPressEnter">
          </div>

          <div class="buttons">
            <div class="button search_button" @click="getSearchResults"><span>Search</span></div>
            <div class="upload_button" @click="clickedDownload"></div>
          </div>
        </div>

      </div>

      <div class="results">
        <div v-for="(row, index) in result" :key="index">
          <h3>{{ row.location }}.{{ row.description }}</h3>
          <div class="time">
            <div><span>Start</span><span><p v-if="row.alarmstart">{{ row.alarmstart[0] }}</p> <h3 v-if="row.alarmstart">{{ row.alarmstart[1] }}</h3></span></div>
            <div><span>End</span><span><p v-if="row.alarmend">{{ row.alarmend[0] }}</p> <h3 v-if="row.alarmend">{{ row.alarmend[1] }}</h3></span></div>
            <div><span>Duration</span><span><p>Minutes </p><h3>{{ row.duration }}</h3></span></div>
          </div>
          <div class="message">
            <div><span>Message</span><span>{{ row.message }}</span></div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script setup>
    import {ref, watch} from 'vue';
    let result = ref("");
    let keywords = ref("");
    let startDate = ref("");
    let endDate = ref("");
    let spinner = ref(false);
    let csvUrl = ref("");
    let bdlApiPath = "https://boeing.dreamlifter.wave7.co/screens/projects/BDL/scripts/";

    import { inject } from 'vue';
    let isarchive = inject('isarchive');
    if(isarchive==undefined) isarchive="false";
    if(isarchive=="true") {
      bdlApiPath = "https://archive.boeing.dreamlifter.wave7.co/screens/projects/BDL/scripts/";
    }
    
    async function clickedDownload(){
        const fileName = csvUrl.value;
        try {
            const response = await fetch(fileName)
            const blob = await response.blob();
            const url = await URL.createObjectURL(blob)

            const a = document.createElement("a");
            a.href = url;
            a.download = "alarm"+startDate.value+"-to-"+endDate.value+".csv";
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
        } catch(err) {
            console.log({ err })
        }
    }
    function handleSelectDateStart(event) {
        console.log('date start', event);
        handleSelectedDate("start", event);
    }

    function handleSelectDateEnd(event) {
        console.log('date end', event)
        handleSelectedDate("end", event);
    }

    function handleSelectedDate(cal, event) {
        let dt = event.target.valueAsDate;
        dt = dt.toISOString();
        dt = dt.split("T");
        if (cal == "start") {
            startDate.value = dt[0];
        } else {
            endDate.value = dt[0];
        }
    }

    watch(startDate, (n) => {
        csvUrl.value = bdlApiPath + "getSearchv8.php?start=" + n + "&end=" + endDate.value + "&keywords="+keywords.value+"&type=file"; 
        console.log("CSV",csvUrl.value);
    })

    watch(endDate, (n) => {
        csvUrl.value = bdlApiPath + "getSearchv8.php?start=" + startDate.value + "&end=" + n + "&keywords="+keywords.value+"&type=file"; 
        console.log("CSV",csvUrl.value);
    })

    watch(keywords, (n) => {
        csvUrl.value = bdlApiPath + "getSearchv8.php?start=" + startDate.value + "&end=" + endDate.value + "&keywords="+n+"&type=file"; 
    })

    function onPressEnter() {
      getSearchResults();
    }

    function getSearchResults() {
        result.value = ["Please wait while we load the data..."];
        const url = bdlApiPath + "getSearchv8.php?start=" + startDate.value + "&end=" + endDate.value + "&keywords="+keywords.value+"&type=html";
        
        fetch(url, {
            method: 'Get',
            headers: {'Content-type': 'application/json'},
        }).then((res) => res.json()).then((response) => {
          result.value = response;
          console.log(result.value,'result.value')
          spinner.value = false;
        }).catch((error) => {
            console.log("error loading series data:", error);
            spinner.value = false;
        });
    }

    // function getCsvFile() {
    //     //result.value = ["Please wait while we load the data..."];
    //     const url = bdlApiPath + 'getSearchv8.php?start=' + startDate.value + '&end=' + endDate.value + "&keywords="+keywords.value+"&type=file";
        
    //     fetch(url, {
    //         method: 'Get',
    //         headers: {'Content-type': 'application/json'},
    //     }).then((res) => res.json()).then((response) => {
            
    //         spinner.value = false;
    //     }).catch((error) => {
    //         console.log("error loading series data:", error);
    //         spinner.value = false;
    //     });
    // }
    //Get html results
    //"screens/projects/BDL/scripts/getSearch.php?start="+start+"&end="+end+"&keywords="+keywords+"&type=html"

    //Get CSV file
    //"screens/projects/BDL/scripts/getSearch.php?start="+start+"&end="+end+"&keywords="+keywords+"&type=file"
</script>

<style lang="scss" scoped>
.main_v8{
  padding: 0;
  background-color: #fff;
}
.results{
  padding: 10px 50px;
  @media screen and (min-width: 1025px) and (max-width: 1250px) {
    padding: 10px 20px;
  }
  @media screen and (min-width: 641px) and (max-width: 1024px) {
    padding: 10px 30px;
  }
  @media screen and (max-width: 640px) {
    padding: 10px 20px;
  }
  >div{
    border-top: 1px solid #2525251A;
    h3{
      margin: 10px 0;
    }
  }
  .time{
    display: flex;
    flex-direction: row;
    >div:not(:last-child){
      margin-right: 10px;
    }
    >div{
      flex-basis: 33.3%;
      display: flex;
      flex-direction: row;
      color: #444;
      @media screen and (max-width: 640px) {
        flex-direction: column;
      }
      span:first-child{
        margin-right: 10px;
        min-height: 55px;
        line-height: 55px;
        width: 4.2rem;
        display: flex;
        flex-wrap: wrap;
        align-content: center;
        @media screen and (max-width: 640px) {
          min-height: auto;
          line-height: normal;
          margin-bottom: 5px;
        }
      }
      span:last-child{
        flex-grow: 1;
        min-height: 55px;
        display: flex;
        flex-wrap: wrap;
        align-content: center;
        padding-left: 10px;
        border-radius: 10px;
        background-color: #7878780D;
        vertical-align: middle;
        flex-direction: row;
        h3{
          text-align: center;
          flex-grow: 1;
          width: max-content;
          flex-shrink: 0;
        }
        p{
          display: flex;
        }
        p,h3{
          margin: 0px;
          flex-wrap: wrap;
          align-content: center;
        }
        p{
          margin-right: 10px;
        }
        @media screen and (max-width: 1140px) {
          flex-direction: column;
          padding-left: 0;
          p,h3{
            width: 100%;
            justify-content: center;
            margin-right: 0px;
          }
        }
      }
    }
  }
  .message{
    display: flex;
    flex-direction: row;
    margin: 20px 0 10px;
    @media screen and (max-width: 640px) {
      margin: 5px 0px 5px;
    }
    >div{
      display: flex;
      flex-direction: row;
      flex-grow: 1;
      flex-shrink: 0;
      @media screen and (max-width: 640px) {
        flex-shrink: 1;
        flex-grow: 0;
      }
      span:first-child{
        margin-right: 30px;
        display: flex;
        flex-wrap: wrap;
        align-content: center;
      }
      span:last-child{
        padding: 10px;
        background-color: #7878780D;
        border-radius: 10px;
        display: flex;
        flex-grow: 1;
        flex-shrink: 0;
        @media screen and (max-width: 640px) {
          flex-shrink: 1;
          flex-grow: 0;
        }
      }
    }
  }
}
.plotarea {
  // height: 100%;
  background-color: #fff;
  .plotarea_header {
    min-height: 80px;
    line-height: 40px;
    background-color: #f2f2f2;
    padding: 10px 50px;
    box-sizing: border-box;
    font-size: 1rem;
    font-weight: 400;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    @media screen and (min-width: 1025px) and (max-width: 1250px) {
      padding: 10px 20px;
    }
    @media screen and (min-width: 641px) and (max-width: 1024px) {
      padding: 10px 30px;
    }
    @media screen and (max-width: 640px) {
      padding: 10px 20px;
      flex-direction: column;
      flex-wrap: wrap;
      align-items: unset;
    }
    h3{
      flex-shrink: 1;
      flex-grow: 0;
      margin: 0;
      margin-right: 3rem;
      min-width: 173px;
      @media screen and (max-width: 1250px) {
        margin-right: 10px;
      }
      @media screen and (max-width: 1024px) {
        min-width: auto;
        width: min-content;
        margin-right: 20px;
      }
      @media screen and (max-width: 640px) {
        width: auto;
      }
    }
    .dates{
      display: flex;
      flex-direction: row;
      @media screen and (max-width: 640px) {
        justify-content: space-between;
      }
      .date{
        display: flex;
        flex-direction: row;
        span{
          margin-right: 10px;
        }
        @media screen and (max-width: 900px) {
          flex-direction: column;
        }
        @media screen and (max-width: 640px) {
          width: calc(50% - 4px);
        }
      }
    }
  }
}

.actions{
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  .keyword_name{
    flex-grow: 1;
    height: 40px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    @media screen and (max-width: 900px) {
      height: auto;
    }
    span{
      margin-right: 10px;
    }
    input{
      -webkit-appearance: none;
      text-indent: 12px;
      width: 100%;
      background-color: #fff;
      border: 1px solid #BCBCBC;
      border-radius: 10px;
      color: #000;
      height: 40px;
      font-size: 1rem;
      font-weight: 500;
      outline: none;
      box-sizing: border-box;
      padding: 0;
    }
    @media screen and (max-width: 900px) {
      flex-direction: column;
      align-items: baseline;
      input{
        flex-shrink: 0;
      }
    }
  }
  
    .buttons{
      display: flex;
      flex-direction: row;
      @media screen and (max-width: 900px) {
        flex-direction: column;
      }
      @media screen and (max-width: 640px) {
        flex-direction: row;
        align-items: self-end;
      }
    }
    .search_button{
      font-size: 1rem;
      padding: 0 20px;
      height: 40px;
      line-height: 40px;
      text-transform: capitalize;
      font-weight: 400;
      @media screen and (max-width: 1024px) {
        background-image: url(../../public/img/boeing/search.svg);
        background-repeat: no-repeat;
        background-position: center;
        span{
          display: none;
        }
      }
      @media screen and (max-width: 900px) {
        margin-bottom: 5px;
      }
      @media screen and (max-width: 640px) {
        margin-bottom: 0px;
      }
    }
    .upload_button{
      height: 40px;
      aspect-ratio: 1/1;
      border-radius: 8px;
      background-color: #006C3E;
      background-image: url(../../public/img/boeing/upload.svg);
      background-repeat: no-repeat;
      background-position: center;
      flex-grow: 0;
    }
    .upload_button,.search_button{
      margin-left: 10px;
      cursor: pointer;
    }
}
.datepicker {
  height: 40px;
  line-height: 40px;
  box-sizing: border-box;
  position: relative;
  border: none;
  border-radius: 8px;
  outline: 0;
  cursor: pointer;
  font-family: 'Inter', sans-serif;
  margin: 0;
  margin-right: 20px;
  font-size: 1rem;
  text-align: center;
  color: #252525;
  background-color: #2525251A;
   -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
  @media screen and (max-width: 640px) {
    margin-right: 0px;
  }
}
.datepicker::-webkit-calendar-picker-indicator {
  background: transparent;
  color: transparent;
  cursor: pointer;
  height: auto;
  width: auto;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  font-family: 'Inter', sans-serif;
}
input[type="date"]::-webkit-datetime-edit-text,
input[type="date"]::-webkit-datetime-edit-month-field,
input[type="date"]::-webkit-datetime-edit-day-field,
input[type="date"]::-webkit-datetime-edit-year-field {
  font-family: 'Inter', sans-serif;
}
</style>